import React from "react"
import { graphql } from "gatsby"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  return (
    <div className="blog-post-container">
      {markdownRemark && (
        <div className="blog-post">
          <h1>{markdownRemark.frontmatter.title}</h1>
          <h2>{markdownRemark.frontmatter.date}</h2>
          <img
            alt={`${markdownRemark.frontmatter.title}`}
            src={`${markdownRemark.frontmatter.image}250x250/`}
          />
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
          />
        </div>
      )}
    </div>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        image
      }
    }
  }
`
